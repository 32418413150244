const printcss = `
@charset "utf-8";
@media print {

/* reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,button{margin:0;padding:0}
fieldset,img{border:0 none}
dl,ul,ol,menu,li{list-style:none}
blockquote, q{quotes: none}
blockquote:before, blockquote:after,q:before, q:after{content:'';content:none}
input,select,textarea,button{vertical-align:middle}
input::-ms-clear{display:none}
button{border:0 none;background-color:transparent;cursor:pointer}
body{overflow-x:auto;min-width:1290px;background:#fff}
body,th,td,input,select,textarea,button{font-size:12px;line-height:1.5;font-family:'Noto Sans KR',AppleSDGothicNeo-Regular,'Malgun Gothic','맑은 고딕',sans-serif;color:#555;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none}
a{color:#555;text-decoration:none}
/* a:active, a:hover{text-decoration:underline} */
a:active{background-color:transparent}
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal}

/* global */
#kakaoIndex{overflow:hidden;position:absolute;left:-9999px;width:0;height:1px;margin:0;padding:0}
.ir_pm{display:block;overflow:hidden;font-size:0;line-height:0;text-indent:-9999px}
.ir_caption{overflow:hidden;width:1px;font-size:1px;line-height:0;text-indent:-9999px}
.screen_out{overflow:hidden;position:absolute;width:0;height:0;line-height:0;text-indent:-9999px}
.show{display:block}
.hide{display:none}
}
/* layout */
.wrap_print{overflow:hidden}
.page_print{width:994px;padding:48px 48px;page-break-after:always}

/* -- 상단고정 발주 번호 -- */
.area_number{text-align:right}
.badge_number{display:inline-block;height:30px;padding:0 20px;border-radius:16px;border:1px solid #bbb;line-height:31px;color:#555;vertical-align:top}
.badge_number .tit_number{display:inline-block;padding-right:13px;font-weight:500;font-size:15px;vertical-align:top;letter-spacing:-.04em}
.badge_number .txt_number{display:inline-block;font-weight:500;font-size:16px;vertical-align:top;letter-spacing:-.03em}

/* -- 컨텐츠 -- */
.cont_print{position:relative}
.cont_print .tit_print{font-weight:500;font-size:28px;line-height:41px;color:#555;letter-spacing:-.025em}

/* -- 프린트시 스타일 재설정 -- */
@media print {
  @page{margin:0}
  body{margin:1.6cm}
  .page_print{margin:0;padding-bottom:0;border:0 none}
  .area_btn{display:none}
}

/* -- 컨텐츠 상세 -- */
/* 공통 */
.bar_info{display:block;width:46px;margin-top:-1px;border-top:1px solid #222}
.info_left{float:left;width:554px}
.info_right{float:right;width:440px}

/* -- OrderPrintInfo -- */
/* 정보영역 */
.area_info_company{overflow:hidden;padding-top:40px}
.area_info_company .tit_info{display:block;padding:24px 0;font-weight:500;font-size:14px;line-height:20px;color:#222}
.area_info_company .group_info{overflow:hidden}
.area_info_company .group_info + .group_info{padding-top:32px}
.area_info_company dl{float:left;letter-spacing:-.05em}
.area_info_company dl:first-child{width:180px}
.area_info_company dl + dl{padding-left:40px}
.area_info_company dt{padding-bottom:4px;font-weight:normal;font-size:13px;line-height:19px;color:#222}
.area_info_company dd{display:block;min-height:20px;font-size:14px;line-height:20px;color:#222}
.area_info_company dd .txt_sign{padding-left:32px}
/* 정보영역_일자 */
.area_info{padding-top:40px}
.area_info dl{float:left;width:226px;letter-spacing:-.05em}
.area_info dt{font-weight:normal;font-size:13px;line-height:19px;color:#222}
.area_info dd{padding-top:12px;font-size:21px;line-height:31px;color:#555}
/* 정보영역_합계금액 */
.area_info{overflow:hidden}
.area_info dl{padding-top:24px}
.area_info .box_total{float:none;width:100%;margin-top:-1px;padding:23px 32px;border:1px solid #bbb;background-color:#FFF8D9;box-sizing:border-box}
.area_info .box_total dd{float:none;padding:0;font-size:30px;line-height:45px;color:#555;text-align:right}
.area_info .box_total .txt_util{display:inline-block;margin-top:15px;font-size:18px;line-height:27px;vertical-align:top}
.area_info + .area_info{padding-top:41px}

/* -- OrderPrintEstimate -- */
/* 테이블 상단 문구 */
.tbl_top{overflow:hidden;padding:24px 0 8px;font-size:12px;line-height:18px;color:#555}
.tbl_top .tit_head{float:left;font-weight:normal}
.tbl_top .txt_head{float:right}
/* 테이블 공통 */
.tbl_print{overflow:hidden;border-top:1px solid #222}
.tbl_print table{width:100%;border:0 none;border-collapse:collapse;border-spacing:0;table-layout:fixed}
.tbl_print + .tbl_print{margin-top:16px}
/* 테이블 리스트 타입 */
.tbl_list{padding-bottom:16px}
.tbl_list thead th{height:48px;border-bottom:1px solid #e1e1e1;font-weight:normal;font-size:15px;background-color:#fbfbfb;color:#222}
.tbl_list tbody td{padding:12px;border-bottom:1px solid #e1e1e1;font-size:15px;line-height:23px;color:#555;text-align:center;word-wrap:break-word;word-break:keep-all}
.tbl_list .align_left{text-align:left}

/* file */
.file_view{padding:0}
.file_view .btn_file{display:block;width:100%;border:0 none;font-size:15px;line-height:18px;outline:none;text-align:left}
.file_view .ico_purchase{display:none}
.file_view .txt_file{display:block;font-size:15px;line-height:22px;color:#5551ce;text-decoration:underline;word-wrap:break-word;word-break:keep-all}

/* -- OrderPrintTotal -- */
/* 박스 합계 정보 */
.group_total{overflow:hidden;padding:0 0 56px;page-break-inside:avoid}
.group_total .box_total{float:left;width:270px;margin-left:17px;padding:20px 24px;border:1px solid #e1e1e1}
.group_total .box_total:first-child{margin-left:0}
.group_total .total_head{overflow:hidden}
.group_total .total_head .tit_total{float:left;font-weight:normal;font-size:12px;color:#222}
.group_total .total_head .count_total{float:right;font-size:13px;line-height:19px;color:#888}
.group_total .total_head .tit_count{float:left;padding-right:8px}
.group_total .total_head .txt_bar{float:left;width:1px;height:10px;margin:4px 0 5px;border-right:1px solid #e1e1e1}
.group_total .total_head .txt_count{overflow:hidden;padding-left:8px}
.group_total .txt_total{display:block;padding-top:6px;font-size:20px;line-height:29px;color:#555;text-align:right}
.group_total .txt_total .txt_util{display:inline-block;margin-top:6px;font-size:14px;line-height:20px;vertical-align:top}

/* -- orderPrintTip -- */
.area_tip{page-break-inside:avoid}
/* 테이블 뷰 타입 */
.tbl_view{padding-bottom:16px}
.area_tip .tbl_view{margin:0 0 80px}
.tbl_view tbody th{padding:13px 24px;font-size:15px;border-bottom:1px solid #e1e1e1;font-weight:normal;background-color:#fbfbfb;color:#222;vertical-align:top;text-align:left}
.tbl_view tbody td{padding:13px 24px;font-size:15px;border-left:1px solid #e1e1e1;border-bottom:1px solid #e1e1e1;line-height:22px;color:#555}
/* 하단 알림 문구 */
.desc_alert{padding:13px 0;border:1px solid #F8626C;font-size:15px;line-height:22px;background-color:#FEF7F7;color:#F8626C;text-align:center}

/* -- OrderPrintTerm -- */
/* 약관 */
.area_term{font-size:15px;line-height:22px;color:#555;letter-spacing:-.05em}
.tit_term{display:block;margin:20px 0 4px;font-weight:normal}
.tit_term:first-child{margin-top:0}
.list_term > li{padding-left:20px;text-indent:-20px}
.list_term > li:last-child .list_term2{margin-bottom:0}
.list_term2{margin:6px 0}
.list_term2 > li{padding-left:15px;text-indent:-15px}
.desc_term{font-size:15px;line-height:22px;color:#555;letter-spacing:-.05em}

/* 서약서 하단 정보영역 */
.box_oath{margin:48px 0 40px;padding:40px 56px;border:1px solid #e1e1e1;border-top:1px solid #222}
.box_oath .bar_info{margin:24px 0}
.box_oath .line_info{overflow:hidden}
.box_oath dl{float:left;font-size:15px;line-height:22px}
.box_oath dl+dl{padding-left:56px}
.box_oath dl:last-child{float:none;overflow:hidden}
.box_oath dt{color:#222}
.box_oath dd{padding-top:8px;color:#555;word-wrap:break-word;word-break:keep-all}
.box_oath + .desc_term{padding:2px 0 141px}
/* 버튼 */
.area_btn{padding-bottom:49px;text-align:center}
.btn_agree{display:inline-block;min-width:120px;height:40px;padding:10px 32px;border-radius:2px;border:1px solid #ffcd00;font-weight:700;font-size:14px;line-height:20px;background-color:#ffcd00;color:#222;vertical-align:top}
.area_btn + .desc_term{padding-bottom:53px}
/* 첨부파일 */
.area_file .btn_file{display:block;font-size:15px;line-height:22px;color:#5551CE;text-decoration:underline;text-align:left}
.area_file .btn_file + .btn_file{margin-top:8px}
`;
export default printcss;
